<template>
  <div>
    <a-card
      :style="{ marginBottom: '1em' }"
      :header="$t('common.search')"
      :editable="false"
    >
      <template
        #header-buttons
      >
        <div class="is-pulled-right is-flex buttons">
          <b-button
            icon-left="file-excel-box"
            @click="exportCsv"
          >
            {{ $t('common.navigation.export_to_csv') }}
          </b-button>
          <b-button
            icon-left="filter-remove"
            @click="() => $store.dispatch('failureParts/resetFilters')"
          >
            {{ $t('common.navigation.reset_filters') }}
          </b-button>
          <a-select
            :manual-doc="userDoc"
            field="routes.failureParts.orderBy"
            :label="$t('common.order')"
            :options="keys($store.getters['failureParts/orderOptions'])"
            :labels="$store.getters['failureParts/orderOptions']"
          />
        </div>
      </template>

      <filters />
    </a-card>
    <a-table
      :columns="user.routes.failureParts.columns"
      :data="$store.getters['failureParts/displayedItems']"
    >
      <template
        #actions="slotProps"
      >
        <a
          :title="$t('common.navigation.go_to_failure')"
        >
          <b-icon
            icon="arrow-up"
            @click.native="goToFailure(slotProps.props.row)"
          />
        </a>
      </template>
    </a-table>
    <more-docs-button
      module="failureParts"
    />
  </div>
</template>

<script>
import ATable from '@/components/ATable'
import MoreDocsButton from '@/components/MoreDocsButton'
import { mapGetters, mapActions } from 'vuex'
import { keys } from 'lodash'
import Filters from './FailureParts/Filters'

export default {
  components: {
    Filters,
    MoreDocsButton
  },
  data() {
    return {
      userDoc: { module: 'user' }
    }
  },
  computed: {
    where () {
      return this.user.routes.failureParts.where
    }
  },
  created () {
    this.$store.dispatch('failureParts/fetchDocs')
  },
  methods: {
    keys,
    goToFailure(failurePart) {
      this.$router.push({ name: 'failure', params: { id: failurePart.failure.id } })
    },
    exportCsv() {
      const filename = `Failure Parts ${this.where.failure && this.where.failure.operator ? this.where.failure.operator.name : ''} - ${this.where.part && this.where.part.supplier ? this.where.part.supplier.name : ''}`
      this.$store.dispatch('failureParts/exportToCsv', { filename })
    }
  }
}
</script>
